<template>
  <!-- 面板 -->
  <div class="panel">
    <!-- 面板头部 -->
    <div class="panel-header">
      <!-- 头部标题 -->
      <p class="panel-header-title">{{ title }}</p>
      <!-- 关闭按钮 -->
      <i
        class="iconfont icon-close panel-header-closeBtn"
        @click="$emit('close')"
      ></i>
    </div>
    <!-- 面板主体 -->
    <div class="panel-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    title: String
  }
};
</script>

<style lang="less" scoped>
.panel {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 330px;
  width: 330px;
  resize: horizontal;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    background-color: #f1f5ff;
    &-title {
      font-size: 20px;
    }
    &-closeBtn {
      cursor: pointer;
      font-size: 24px;
    }
  }
  &-container {
    height: calc(100% - 50px);
  }
}

.como {
  animation: identifier 1s;
}

.go {
  animation: identifier 1s reverse;
}

@keyframes identifier {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
</style>
