<template>
  <!-- 工作空间下拉框 -->
  <el-select class="workSpaceSelect" :value="value" @change="handleChange">
    <el-option
      v-for="option in options"
      :key="option.id"
      :label="option.name"
      :value="option.id"
    />
  </el-select>
</template>

<script>
export default {
  name: 'WorkSpaceSelect',
  props: ['value'],
  data() {
    return {
      selectValue: '',
      options: []
    };
  },
  computed: {
    datasetImportDataDialogVisible: {
      get() {
        return this.$store.state.common.workspaceId;
      },
      set(val) {
        this.$store.commit('common/setWorkspaceId', val);
      }
    }
  },
  mounted() {
    this.getOptionsData();
  },
  methods: {
    getOptionsData() {
      this.$service.workspace.workspaceGetAll().then(data => {
        if (data.data.status === 200) {
          let str = JSON.stringify(data.data.data);
          if (str.includes(this.workspaceId) && this.workspaceId !== '') {
            return;
          } else {
            this.$store.state.common.workspaceId = data.data.data[0].id;
          }
          this.options = data.data.data;
        }
      });
    },
    handleChange(val) {
      this.$store.state.common.workspaceId = val;
    }
  }
};
</script>

<style lang="less" scoped>
.workSpaceSelect {
  width: 166px;
  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    color: #fff;
    font-size: 16px;
    background-color: #507cff;
    border-radius: 12px;
    border: none;
    outline: none;
    &::placeholder {
      color: #fff;
    }
  }
  /deep/ .el-input__icon {
    line-height: 36px;
    color: #fff !important;
  }
}
</style>
