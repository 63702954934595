<template>
  <Drag
    :class="['dataclassAttrSheetPane', isMagnify ? 'isMagnify' : '']"
    :title="dataclassAttrSheetPanelVisible.name"
    @close="dataclassAttrSheetPanelClose"
    :style="{ height: maxHeight + 'px', top: dragTop + 'px' }"
  >
    <template #headerbtn>
      <Search
        v-model="keywords"
        @search="handleSearch"
        @close="handleSearch('close')"
      />
      <list-tooltip :content="magnify ? '放大' : '还原'">
        <i
          :class="
            magnify
              ? 'icon icon-attrSheet-zoomIn'
              : 'icon icon-attrSheet-zoomOut'
          "
          @click="handleMagnify"
        ></i>
      </list-tooltip>
      <list-tooltip
        :content="
          tableEidtData.length !== 0 ? '保存' : '双击表格区域可进行编辑'
        "
      >
        <i
          :class="
            tableEidtData.length !== 0
              ? 'icon icon-attrSheet-save1'
              : 'icon icon-attrSheet-save'
          "
          @click="handleSaveEdit"
        ></i>
      </list-tooltip>
      <Button
        class="editBtn"
        icon="edit"
        @click="handleFieldEdit(dataclassAttrSheetPanelVisible.id)"
        >编辑字段</Button
      >
      <i
        class="icon icon-attrSheet-close"
        @click="dataclassAttrSheetPanelClose"
      ></i>
    </template>
    <template #content>
      <el-table
        ref="fmeaTableRef"
        :data="tableData"
        style="width: 100%"
        :class="[isMagnify ? 'isMagnify' : '']"
        v-loading="loading"
        :max-height="maxHeight - 134"
        :header-cell-style="{
          'text-align': 'center',
          'border-right': '1px solid #dcdcdc'
        }"
        :cell-style="{
          'text-align': 'center',
          'border-right': '1px solid #dcdcdc'
        }"
        v-table-move="['fmeaTableRef']"
        border
      >
        <el-table-column
          v-for="(item, index) in tableTitle"
          :key="index + item.name"
          :prop="item.porp"
          :label="item.name"
          min-width="180"
          :show-overflow-tooltip="item.fieldType !== 3"
        >
          <template slot="header">
            {{ item.name }}
            <span style="color: red" v-show="item.notNull == 1">*</span>
          </template>
          <template slot-scope="scope">
            <!-- 条件判断如果满足则显示表单，否则默认展示文字 -->
            <el-input
              ref="tagEditInput"
              :class="scope.row[item.name + 'Error'] ? 'inputError' : ''"
              v-model="scope.row[item.porp]"
              v-if="
                showInput == item.name + scope.row.id &&
                item.showEdit !== 'no' &&
                item.fieldType !== 3
              "
              :type="item.fieldType == 2 ? 'number' : 'text'"
              :maxlength="item.maxLength"
              show-word-limit
              @blur="changeInput(scope.row, item)"
              @input="e => rulesInput(e, item, scope.row)"
              v-focus
            >
            </el-input>
            <div class="excel" v-else @dblclick="handleDbEdit(scope.row, item)">
              <list-tooltip :content="scope.row[item.name + 'Error']">
                <div
                  class="excel-cornerMark"
                  v-show="scope.row[item.name + 'Error']"
                ></div>
              </list-tooltip>
              <span
                :style="{
                  color: [scope.row[item.name + 'Error'] ? 'red' : '']
                }"
                >{{
                  scope.row[item.porp] ? scope.row[item.porp] : '&emsp;'
                }}</span
              >
            </div>
            <div
              class="promptDialogBox"
              v-show="
                showInput == item.name + scope.row.id &&
                scope.row[item.name + 'Error'] &&
                promptDialogBox
              "
            >
              <div class="promptDialogBox-tip">
                <span> <i class="icon icon-tip"></i> 错误提示:</span>
                <i
                  class="icon icon-attrSheet-close"
                  @click="
                    promptDialogBox = false;
                    showInput = '';
                  "
                />
              </div>
              <div class="promptDialogBox-text">
                {{ scope.row[item.name + 'Error'] }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 自定义字段 -->
        <el-table-column
          v-for="(item, index) in tableTitle1"
          :key="item.name + index"
          :label="item.name"
          min-width="180"
          :show-overflow-tooltip="item.fieldType !== 3"
        >
          <template #header="{ column, $index }">
            <slot :name="item.name" :row="column" :index="$index"></slot>
            {{ item.name }}
            <span style="color: red" v-show="tableTitle1[index].notNull == 1"
              >*</span
            >
          </template>
          <template slot-scope="scope">
            <!-- 条件判断如果满足则显示表单，否则默认展示文字 -->
            <el-input
              ref="tagEditInput"
              :class="scope.row[item.name + 'Error'] ? 'inputError' : ''"
              v-model="scope.row.customField[item.id]"
              v-if="
                showInput == item.name + scope.row.id &&
                item.showEdit !== 'no' &&
                item.fieldType !== 3
              "
              :type="item.fieldType == 2 ? 'number' : 'text'"
              :maxlength="item.maxLength"
              show-word-limit
              @blur="changeInput(scope.row, item)"
              @input="e => rulesInput(e, item, scope.row)"
              v-focus
            >
            </el-input>

            <el-date-picker
              class="datePicker"
              v-model="scope.row.customField[item.id]"
              v-else-if="
                showInput == item.name + scope.row.id &&
                item.porp !== 'id' &&
                item.fieldType === 3
              "
              type="date"
              :clearable="false"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期"
              @change="e => rulesInput(e, item, scope.row)"
              @blur="changeInput(scope.row, item)"
              @focus="foucusDataPicker(scope.row, item)"
            >
            </el-date-picker>

            <div class="excel" v-else @dblclick="handleDbEdit(scope.row, item)">
              <list-tooltip :content="scope.row[item.name + 'Error']">
                <div
                  class="excel-cornerMark"
                  v-show="scope.row[item.name + 'Error']"
                ></div>
              </list-tooltip>
              <span
                :style="{
                  color: [scope.row[item.name + 'Error'] ? 'red' : '']
                }"
              >
                {{
                  scope.row.customField[item.id]
                    ? scope.row.customField[item.id]
                    : '&emsp;'
                }}
              </span>
            </div>
            <div
              class="promptDialogBox"
              v-show="
                showInput == item.name + scope.row.id &&
                scope.row[item.name + 'Error'] &&
                promptDialogBox
              "
            >
              <div class="promptDialogBox-tip">
                <span> <i class="icon icon-tip"></i> 错误提示:</span>
                <i
                  class="icon icon-attrSheet-close"
                  @click="
                    promptDialogBox = false;
                    showInput = '';
                  "
                />
              </div>
              <div class="promptDialogBox-text">
                {{ scope.row[item.name + 'Error'] }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          width="180"
          class="operation"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClickLook(scope.row)"
              type="text"
              style="color: #0466fc; font-size: 16px"
              >查看</el-button
            >
            <div class="cut-offRule"></div>
            <el-button
              @click="handleEdit(scope.row)"
              style="color: #1ecd4f; font-size: 16px"
              type="text"
              >编辑</el-button
            >
            <div class="cut-offRule"></div>
            <el-button
              @click="handleDel(scope.row)"
              style="color: #fe3530; font-size: 16px"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-size="15"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </template>
  </Drag>
</template>

<script>
import $bus from 'p/utils';
import Drag from 'c/basic/Drag';
import Button from 'c/basic/Button';
import Search from 'c/workbench/Search';
import ListTooltip from 'c/basic/ListTooltip.vue';
import { ip } from 'p/urlSplice';
import pointMark from 'a/map/point-mark.png';
export default {
  name: 'DataclassAttrSheetDialog',
  components: {
    Drag,
    Button,
    Search,
    ListTooltip
  },
  data() {
    return {
      tableTitle: [],
      tableTitle1: [],
      tableData: [],
      tableEidtData: [],
      referenceData: [],
      // 当前页数
      page: 1,
      // 数据总数量
      total: 0,
      // 搜索关键词
      keywords: '',
      // 保存状态
      saveStatus: false,
      // 表格输入框
      showInput: '',
      // 窗口高度
      maxHeight: '350',
      // 窗口顶部距离
      dragTop: 479,
      // 窗口放大缩小状态
      magnify: true,
      isMagnify: false,
      // 表格错误提示
      inputErrorText: '',
      // 自定义空值
      customFieldKey: {},
      // 加载动画
      loading: true,
      // 错误提示框
      promptDialogBox: false,
      plotDetail: {}
    };
  },
  computed: {
    // 数据类属性表状态
    dataclassAttrSheetPanelVisible: {
      get() {
        return this.$store.state.data.dataclassAttrSheetPanelVisible;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrSheetPanelVisible', val);
      }
    },
    dataClassFeaturesData: {
      get() {
        return this.$store.state.data.dataClassFeaturesData;
      },
      set(val) {
        this.$store.commit('data/setDataClassFeaturesData', val);
      }
    },
    layerManagerData: {
      get() {
        return this.$store.state.layerData.layerManagerData;
      },
      set(val) {
        this.$store.commit('layerData/setLayerManagerData', val);
      }
    }
  },
  mounted() {
    $bus.$on('refreshAttrList', data => {
      if (data == 1) {
        this.page = 1;
        this.getTableData();
      }
      if (!data || data == this.dataclassAttrSheetPanelVisible.id) {
        this.getTableData();
      }
    });
    $bus.$on('dragArrtTableMaxHeight', () => {
      this.$nextTick(() => {
        // 属性表的高度
        this.maxHeight = document.querySelector(
          '.dataclassAttrSheetPane'
        ).offsetHeight;
      });
    });
  },
  destroyed() {
    $bus.$off('refreshAttrList');
    $bus.$off('dragArrtTableMaxHeight');
  },
  methods: {
    // 去除编辑数据的校验提示参数
    removeUnnecessary() {
      let arr = this.tableEidtData;
      arr.map(item => {
        for (let i in item) {
          if (i.indexOf('Error') !== -1) {
            delete item[i];
          }
        }
      });
      return arr;
    },
    // 保存编辑
    handleSaveEdit() {
      if (this.tableEidtData.length !== 0) {
        let request = '';
        let data = this.dataclassAttrSheetPanelVisible;
        switch (data.dataType) {
          case 1:
            request = 'featurePointAttributeTableEdit';
            break;
          case 3:
            request = 'featureLineAttributeTableEdit';
            break;
          case 5:
            request = 'featurePolygonAttributeTableEdit';
            break;
        }
        const params = {
          classId: data.id,
          dataJson: JSON.stringify(this.removeUnnecessary())
        };
        this.$service.data[request](params)
          .then(data => {
            if (data.data.status === 200) {
              this.$message.success('保存成功');
              this.tableEidtData = [];
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 放大
    handleMagnify() {
      this.isMagnify = true;
      this.magnify = !this.magnify;
      if (this.magnify) {
        this.maxHeight = '350';
        this.dragTop = 479;
      } else {
        this.maxHeight = '770';
        this.dragTop = 59;
      }
      setTimeout(() => {
        this.isMagnify = false;
      }, 1000);
    },
    // 输入框校验
    rulesInput(text, item, row) {
      let tip = this.validateRules(item, text);
      if (tip) {
        row[tip.error] = tip.tipText;
      } else {
        row[item.name + 'Error'] = '';
      }
    },
    // 属性字段校验
    validateRules(item, text) {
      let rule = false;
      let tip = '';
      let tipText = '';
      let required = '该内容为必填项';
      switch (item.fieldType) {
        case 1:
          tip =
            item.maxLength && text.length > item.maxLength
              ? '该内容应为长度' + item.maxLength + '字符以内的文本类型'
              : '';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 2:
          rule = /^(-?\d+)(\.\d+)?$/.test(text);
          tip = !text || rule ? '' : '该内容应为数字类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 3:
          rule = /^(\d{4})-(\d{2})-(\d{2})$/.test(text);
          tip = !text || rule ? '' : '该内容应为日期类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 4:
          rule =
            /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/.test(
              text
            );
          tip = !text || rule ? '' : '该内容应为网址类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 5:
          rule =
            /^1([38][0-9]|4[5-9]|5[0-3,5-9]|66|7[0-8]|9[89])[0-9]{8}$/.test(
              text
            );
          tip = !text || rule ? '' : '该内容应为电话号码类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 11:
          rule =
            /^(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/.test(
              text
            );
          tip = !text || rule ? '' : '该内容应为经度类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        case 12:
          rule = /^([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/.test(
            text
          );
          tip = !text || rule ? '' : '该内容应为纬度类型';
          tipText = !text && item.notNull == '1' ? required : tip;
          break;
        default:
          break;
      }

      if (tipText) {
        const data = {
          error: item.name + 'Error',
          tipText: tipText
        };
        return data;
      }
    },
    // 输入框关闭事件
    changeInput(row, item) {
      if (row[item.name + 'Error']) return (this.promptDialogBox = true);

      this.promptDialogBox = false;
      this.showInput = '';
      let arr = this.tableEidtData;
      if (arr.length !== 0) {
        let status = true;
        arr.map(item => {
          if (item.id == row.id) {
            item = row;
            status = false;
          }
        });
        this.tableEidtData = arr;
        if (status) {
          this.tableEidtData.push(row);
        }
      } else {
        this.tableEidtData.push(row);
      }
      this.tableEidtData.map((item, index) => {
        this.referenceData.map(item1 => {
          if (JSON.stringify(item) == JSON.stringify(item1)) {
            this.tableEidtData.splice(index, 1);
          }
        });
      });
    },
    // 日期输入框获得焦点
    foucusDataPicker(row, item) {
      if (row[item.name + 'Error']) {
        row.customField[item.id] = '';
      }
    },
    // 搜索
    handleSearch(val) {
      if (val == 'close') {
        this.page = 1;
        this.keywords = '';
        this.getTableData();
      } else {
        if (!this.keywords) return;
        this.getTableData();
      }
    },
    // 双击编辑
    handleDbEdit(row, item) {
      if (item.name == 'ID' || this.promptDialogBox) return;
      this.showInput = item.name + row.id;
      this.$nextTick(() => {
        if (item.fieldType !== 3) {
          this.$refs.tagEditInput[0].$el.querySelector('input').focus();
        }
      });
    },
    // 获取表单数据
    getTableData() {
      this.loading = true;
      this.tableEidtData = [];
      let request = '';
      let obj = this.dataclassAttrSheetPanelVisible;
      switch (obj.dataType) {
        case 1:
          request = 'featurePointAttributeTable';
          break;
        case 3:
          request = 'featureLineAttributeTable';
          break;
        case 5:
          request = 'featurePolygonAttributeTable';
          break;
      }
      const params = {
        classId: obj.id,
        page: this.page,
        keywords: this.keywords
      };
      this.$service.data[request](params)
        .then(res => {
          if (res.data.status === 200) {
            if (res.data.totalPage < res.data.page && res.data.totalPage != 0) {
              this.page = 1;
              this.getTableData();
              return;
            }
            this.page = res.data.page;
            this.total = res.data.total;
            this.tableDataProcessing(res.data.data);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 数据处理
    tableDataProcessing(data) {
      let request = '';
      let text = this.dataclassAttrSheetPanelVisible.dataType;
      switch (text) {
        case 1:
          request = 'points';
          break;
        case 3:
          request = 'lines';
          break;
        case 5:
          request = 'polygons';
          break;
      }
      if (this.page == 1) {
        this.customFieldKey = {};
        this.tableTitle1 = [];
        data.customFields.map(item => {
          this.customFieldKey[item.id] = '';
          item.porp = item.id;
          this.tableTitle1.push(item);
        });
      }
      this.tableData = [];
      data[request].map((item, index) => {
        // 系统属性字段
        for (let i in item) {
          if (i !== 'customFieldJson') {
            for (let j in this.tableTitle) {
              const obj = this.tableTitle[j].porp;
              if (obj == i) {
                const data = this.validateRules(this.tableTitle[j], item[i]);
                if (data) {
                  item[data.error] = data.tipText;
                }
              }
            }
          }
          if (i == 'lat' || i == 'lng' || i == 'z') {
            item[i] = JSON.stringify(item[i]);
          }
        }
        this.tableData[index] = item;
        // 自定义属性字段
        if (item.customFieldJson == '{}' || !item.customFieldJson) {
          const element = JSON.parse(JSON.stringify(this.customFieldKey));

          for (let i in element) {
            for (let j in this.tableTitle1) {
              const obj = this.tableTitle1[j].id;
              if (obj == i) {
                const data = this.validateRules(
                  this.tableTitle1[j],
                  element[i]
                );
                if (data) {
                  item[data.error] = data.tipText;
                }
              }
            }
          }
          this.tableData[index].customField = element;
          item.customFieldJson && delete item.customFieldJson;
        } else {
          const element = {
            ...this.customFieldKey,
            ...JSON.parse(item.customFieldJson)
          };

          for (let i in element) {
            for (let j in this.tableTitle1) {
              const obj = this.tableTitle1[j].id;
              if (obj == i) {
                const data = this.validateRules(
                  this.tableTitle1[j],
                  element[i]
                );
                if (data) {
                  item[data.error] = data.tipText;
                }
              }
            }
          }
          this.tableData[index].customField = element;
          item.customFieldJson && delete item.customFieldJson;
        }
      });
      this.referenceData = JSON.parse(JSON.stringify(this.tableData));
      this.loading = false;
    },
    // 打开编辑字段窗口
    handleFieldEdit(id) {
      const data = {
        status: true,
        id: id
      };
      this.$store.commit('data/setDataclassAttrFieldDialogVisible', data);
    },
    // 关闭窗口
    dataclassAttrSheetPanelClose() {
      if (this.tableEidtData.length !== 0) {
        this.$confirm('系统检测到您的数据有更改，是否保存？', '温馨提示', {
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'del',
          distinguishCancelAndClose: true
        })
          .then(() => {
            this.handleSaveEdit();
            this.$store.commit('data/setDataclassAttrSheetPanelVisible', false);
          })
          .catch(action => {
            if (action === 'cancel') {
              this.$store.commit(
                'data/setDataclassAttrSheetPanelVisible',
                false
              );
            }
          });
      } else {
        this.$store.commit('data/setDataclassAttrSheetPanelVisible', false);
      }
    },
    // 点击查看
    handleClickLook(row) {
      let { dataType, layerId } = this.dataclassAttrSheetPanelVisible;
      if (layerId) {
        this.showLayerData(row, 'check');
      } else {
        this.showData(row, 'check');
      }

      let compass = [];
      if (dataType == 1) {
        compass = [row.lng, row.lat, 5000];
        this.$store.commit('baseMap/setFlyToConfig', {
          compass
        });
      } else {
        const type = {
          3: 'line',
          5: 'polygon'
        };
        this.$service.feature[type[dataType] + 'Get']({ id: row.id }).then(
          res => {
            if (res.status == 200) {
              const { x, y } = res.data[type[dataType]][0];
              compass = [x, y, 5000];
              this.$store.commit('baseMap/setFlyToConfig', {
                compass
              });
            }
          }
        );
      }
    },
    // 打开编辑数据窗口
    handleEdit(row) {
      let { dataType, layerId } = this.dataclassAttrSheetPanelVisible;
      if (layerId) {
        this.showLayerData(row, 'edit');
      } else {
        this.showData(row, 'edit');
      }
      // 定位坐标
      let compass = [];
      // 获取定位位置信息
      if (dataType == 1) {
        compass = [row.lng, row.lat, 5000];
        this.$store.commit('baseMap/setFlyToConfig', {
          compass
        });
      } else {
        const type = {
          3: 'line',
          5: 'polygon'
        };
        this.$service.feature[type[dataType] + 'Get']({ id: row.id }).then(
          res => {
            if (res.status == 200) {
              const { x, y } = res.data[type[dataType]][0];
              compass = [x, y, 5000];
              this.$store.commit('baseMap/setFlyToConfig', {
                compass
              });
            }
          }
        );
      }
    },
    // 删除数据
    handleDel(row) {
      this.$confirm(`确定删除“${row.name}”元素吗？`, '删除数据', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let req = '';
          let delFeatures = [];
          const { id, dataType, layerId } = this.dataclassAttrSheetPanelVisible;
          let type = layerId ? 'layerManagerFeature' : 'dataFeature';
          // 数据管理数据
          if (type === 'dataFeature') {
            switch (dataType) {
              case 1:
                req = 'pointDel';
                delFeatures = this.dataClassFeaturesData[id]?.Point;
                break;
              case 3:
                req = 'lineDel';
                delFeatures = this.dataClassFeaturesData[id]?.Polyline;
                break;
              case 5:
                req = 'polygonDel';
                delFeatures = this.dataClassFeaturesData[id]?.Polygon;
                break;
            }
          }
          // 图层管理
          if (type === 'layerManagerFeature') {
            switch (dataType) {
              case 1:
                req = 'pointDel';
                delFeatures = this.layerManagerData[layerId]?.Point;
                break;
              case 3:
                req = 'lineDel';
                delFeatures = this.layerManagerData[layerId]?.Polyline;
                break;
              case 5:
                req = 'polygonDel';
                delFeatures = this.layerManagerData[layerId]?.Polygon;
                break;
            }
          }
          if (!delFeatures) delFeatures = [];
          this.loading = true;
          this.$service.feature[req]({ id: row.id })
            .then(data => {
              if (data.status == 200) {
                // 删除元素
                const delIndex = delFeatures.findIndex(
                  delFeature => delFeature.featureData.uuid === row.id
                );
                const delFeature = delFeatures[delIndex];
                const editState = delFeature
                  ? delFeature.edit || delFeature.featureData.drag
                  : false;
                if (delIndex !== -1) {
                  delFeatures.splice(delIndex, 1);
                }
                // 关闭展示的信息窗口
                $bus.$emit('attributeListDel', {
                  id: row.id,
                  editState
                });
                this.$message.success(data.msg);
                this.getTableData();
              }
            })
            .catch()
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 显示图层管理单点数据
    showLayerData(row, type) {
      const { dataType, layerId } = this.dataclassAttrSheetPanelVisible;
      const featureType = {
        1: 'Point',
        3: 'Polyline',
        5: 'Polygon'
      }[dataType];
      let layerManagerData = this.layerManagerData;
      // 查找本地数据
      if (layerManagerData[layerId]) {
        let arr = layerManagerData[layerId][featureType];
        if (arr) {
          if (JSON.stringify(arr).includes(row.id)) {
            arr.map(item => {
              if (item.featureData.dialogData.id === row.id) {
                item.show = true;
                $bus.$emit('attributeListEdit', {
                  featureType,
                  id: row.id,
                  state: type,
                  from: 'layerManager',
                  layerId
                });
              }
            });
          } else {
            this.getShowLayerData(row, type, 1);
          }
        } else {
          this.getShowLayerData(row, type);
        }
      } else {
        // 没有本地对应数据 请求后端数据
        this.getShowLayerData(row, type);
      }
    },
    getShowLayerData(row, type, val) {
      const { dataType, layerId } = this.dataclassAttrSheetPanelVisible;
      let layerManagerData = this.layerManagerData;
      const featureType = {
        1: 'Point',
        3: 'Polyline',
        5: 'Polygon'
      }[dataType];
      let req = {
        1: 'pointGet',
        3: 'lineGet',
        5: 'polygonGet'
      };
      Promise.all([
        this.$service.feature[req[dataType]]({ id: row.id }),
        this.$service.layer.layerGetDetailAndStyle({
          id: layerId,
          selectAllStyle: 1
        })
      ]).then(res => {
        const { status, data } = res[0];
        const { status: layerStatus, data: layerData } = res[1].data;
        if (status === 200 && layerStatus === 200) {
          let featureData = {};
          switch (dataType) {
            case 1:
              {
                const icon = layerData?.iconStyle?.url
                  ? ip.urlSplice(layerData.iconStyle.url)
                  : pointMark;
                featureData = {
                  coordinates: [data.lng, data.lat, data.z],
                  icon,
                  show: true,
                  featureData: {
                    type: 'layerManagerFeature',
                    featureType: 'Point',
                    uuid: data.id,
                    drag: type == 'edit' ? true : false,
                    dialogData: {
                      layerId: layerId,
                      id: data.id
                    }
                  }
                };
              }
              break;
            case 3:
              {
                let coordinates = data.line.map(coord => [
                  coord.x,
                  coord.y,
                  coord.z
                ]);
                let segmentColors = layerData.lineStyle
                  ? layerData.lineStyle.fillColor.map(color => {
                      const hex = color.slice(0, 7);
                      const opacity = color.slice(7, 9)
                        ? (
                            (parseInt(color.slice(7, 9), 16) * (100 / 255)) /
                            100
                          ).toFixedNum(2)
                        : 1;
                      return [hex, opacity];
                    })
                  : [['#00A2E8', 1]];
                featureData = {
                  coordinates,
                  segmentColors,
                  show: true,
                  edit: type == 'edit' ? true : false,
                  change: e => {
                    const { type, index, point } = e;
                    let coordinate = [];
                    if (point) {
                      coordinate = [
                        point.longitude,
                        point.latitude,
                        point.altitude
                      ];
                    }
                    $bus.$emit('pointChange', { type, coordinate, index });
                    switch (type) {
                      case 'add':
                        featureData.coordinates.splice(index, 0, coordinate);
                        break;
                      case 'remove':
                        featureData.coordinates.splice(index, 1);
                        break;
                      case 'set':
                        featureData.coordinates[index] = coordinate;
                        break;
                    }
                  },
                  featureData: {
                    type: 'layerManagerFeature',
                    featureType: 'Polyline',
                    uuid: data.id,
                    dialogData: {
                      layerId: layerId,
                      id: data.id
                    }
                  }
                };
              }
              break;
            case 5:
              {
                let polygon = data.polygon;
                // 删除最后一个首尾相连的点
                polygon.pop();
                let coordinates = polygon.map(coord => [
                  coord.x,
                  coord.y,
                  coord.z
                ]);
                let fillColor = layerData.polygonStyle
                  ? layerData.polygonStyle.fillColor
                  : '#00A2E8B3';
                const hex = fillColor.slice(0, 7);
                const opacity = fillColor.slice(7, 9)
                  ? (
                      (parseInt(fillColor.slice(7, 9), 16) * (100 / 255)) /
                      100
                    ).toFixedNum(2)
                  : 1;
                featureData = {
                  coordinates,
                  fillColor: [hex, opacity],
                  show: true,
                  edit: type == 'edit' ? true : false,
                  change: e => {
                    const { type, index, point } = e;
                    let coordinate = [];
                    if (point) {
                      coordinate = [
                        point.longitude,
                        point.latitude,
                        point.altitude
                      ];
                    }
                    $bus.$emit('pointChange', { type, coordinate, index });
                    switch (type) {
                      case 'add':
                        featureData.coordinates.splice(index, 0, coordinate);
                        break;
                      case 'remove':
                        featureData.coordinates.splice(index, 1);
                        break;
                      case 'set':
                        featureData.coordinates[index] = coordinate;
                        break;
                    }
                  },
                  featureData: {
                    type: 'layerManagerFeature',
                    featureType: 'Polygon',
                    uuid: data.id,
                    dialogData: {
                      layerId: layerId,
                      id: data.id
                    }
                  }
                };
              }
              break;
          }
          if (val == 1) {
            // 数据类有数据追加在后面
            layerManagerData[layerId][featureType].push(featureData);
          } else {
            // 数据类无数据 添加
            let layerFeatureData = layerManagerData[layerId];
            if (layerFeatureData) {
              this.$set(layerFeatureData, featureType, [featureData]);
            } else {
              layerFeatureData = {};
              layerFeatureData[featureType] = [featureData];
              this.$set(layerManagerData, layerId, layerFeatureData);
            }
          }
          $bus.$emit('attributeListEdit', {
            featureType,
            id: row.id,
            state: type,
            from: 'layerManager',
            layerId
          });
        }
      });
    },
    // 显示数据管理单点数据
    showData(row, type) {
      const { dataType, id } = this.dataclassAttrSheetPanelVisible;
      const featureType = {
        1: 'Point',
        3: 'Polyline',
        5: 'Polygon'
      }[dataType];
      // 查询本地数据, 存在显示
      if (
        JSON.stringify(this.dataClassFeaturesData).includes(id) &&
        !this.$store.state.data.dataNeedUpdate.includes(id)
      ) {
        const arr = this.dataClassFeaturesData[id][featureType];
        if (JSON.stringify(arr).includes(row.id)) {
          arr.map(item => {
            if (item.featureData.dialogData.id === row.id) {
              item.show = true;
              $bus.$emit('attributeListEdit', {
                featureType,
                id: row.id,
                state: type,
                from: 'dataManager'
              });
            }
          });
        } else {
          // 没有本地对应数据 请求后端数据
          this.getShowData(row, type, 1);
        }
      }
      // 没有本地数据，请求后端数据
      else {
        this.getShowData(row, type);
      }
      $bus.$emit('refreshDataClassDataShowEdit');
    },
    // 请求显示数据(数据管理页面)
    getShowData(row, type, val) {
      let req = '';
      let featureType = '';
      const { dataType, id } = this.dataclassAttrSheetPanelVisible;
      const dataClassFeaturesData = this.dataClassFeaturesData; // 数据类数据集合
      if (!dataClassFeaturesData[id]) {
        this.$set(dataClassFeaturesData, id, {});
      }
      switch (dataType) {
        case 1:
          req = 'pointGet';
          featureType = 'Point';
          break;
        case 3:
          req = 'lineGet';
          featureType = 'Polyline';
          break;
        case 5:
          req = 'polygonGet';
          featureType = 'Polygon';
          break;
      }

      this.$service.feature[req]({
        id: row.id
      }).then(res => {
        const { status, data } = res;
        if (status === 200) {
          let featureData = {};
          switch (dataType) {
            case 1:
              {
                const icon = data.iconUrl
                  ? ip.urlSplice(data.iconUrl)
                  : pointMark;
                featureData = {
                  coordinates: [data.lng, data.lat, data.z],
                  icon,
                  show: true,
                  featureData: {
                    type: 'dataFeature',
                    featureType: 'Point',
                    uuid: data.id,
                    drag: type == 'edit' ? true : false,
                    dialogData: {
                      classId: data.classId,
                      id: data.id
                    }
                  }
                };
              }
              break;
            case 3:
              {
                let coordinates = data.line.map(coord => [
                  coord.x,
                  coord.y,
                  coord.z
                ]);
                let segmentColors = data.lineStyle
                  ? data.lineStyle.fillColor.map(color => {
                      const hex = color.slice(0, 7);
                      const opacity = color.slice(7, 9)
                        ? (
                            (parseInt(color.slice(7, 9), 16) * (100 / 255)) /
                            100
                          ).toFixedNum(2)
                        : 1;
                      return [hex, opacity];
                    })
                  : [['#00A2E8', 1]];
                featureData = {
                  coordinates,
                  segmentColors,
                  show: true,
                  edit: type == 'edit' ? true : false,
                  change: e => {
                    const { type, index, point } = e;
                    let coordinate = [];
                    if (point) {
                      coordinate = [
                        point.longitude,
                        point.latitude,
                        point.altitude
                      ];
                    }
                    $bus.$emit('pointChange', { type, coordinate, index });
                    switch (type) {
                      case 'add':
                        featureData.coordinates.splice(index, 0, coordinate);
                        break;
                      case 'remove':
                        featureData.coordinates.splice(index, 1);
                        break;
                      case 'set':
                        featureData.coordinates[index] = coordinate;
                        break;
                    }
                  },
                  featureData: {
                    type: 'dataFeature',
                    featureType: 'Polyline',
                    uuid: data.id,
                    dialogData: {
                      classId: data.classId,
                      id: data.id
                    }
                  }
                };
              }
              break;
            case 5:
              {
                let polygon = data.polygon;
                // 删除最后一个首尾相连的点
                polygon.pop();
                let coordinates = polygon.map(coord => [
                  coord.x,
                  coord.y,
                  coord.z
                ]);
                let fillColor = data.polygonStyle
                  ? data.polygonStyle.fillColor
                  : '#00A2E8B3';
                const hex = fillColor.slice(0, 7);
                const opacity = fillColor.slice(7, 9)
                  ? (
                      (parseInt(fillColor.slice(7, 9), 16) * (100 / 255)) /
                      100
                    ).toFixedNum(2)
                  : 1;
                featureData = {
                  coordinates,
                  fillColor: [hex, opacity],
                  show: true,
                  edit: type == 'edit' ? true : false,
                  change: e => {
                    const { type, index, point } = e;
                    let coordinate = [];
                    if (point) {
                      coordinate = [
                        point.longitude,
                        point.latitude,
                        point.altitude
                      ];
                    }
                    $bus.$emit('pointChange', { type, coordinate, index });
                    switch (type) {
                      case 'add':
                        featureData.coordinates.splice(index, 0, coordinate);
                        break;
                      case 'remove':
                        featureData.coordinates.splice(index, 1);
                        break;
                      case 'set':
                        featureData.coordinates[index] = coordinate;
                        break;
                    }
                  },
                  featureData: {
                    type: 'dataFeature',
                    featureType: 'Polygon',
                    uuid: data.id,
                    dialogData: {
                      classId: data.classId,
                      id: data.id
                    }
                  }
                };
              }
              break;
          }
          if (val == 1) {
            // 数据类有数据追加在后面
            dataClassFeaturesData[id][featureType].push(featureData);
          } else {
            // 数据类无数据 添加
            let dataClassFeatureData = {};
            dataClassFeatureData[featureType] = [featureData];
            dataClassFeatureData.remark = '未加载全部数据';

            // 更新数据到地图
            this.$store.commit('data/setDataClassFeaturesData', {
              key: id,
              val: dataClassFeatureData
            });
          }

          $bus.$emit('attributeListEdit', {
            featureType,
            id: data.id,
            state: type,
            from: 'dataManager'
          });
        }
      });
    },
    // 分页器
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    }
  },
  watch: {
    dataclassAttrSheetPanelVisible: {
      immediate: true,
      handler(data) {
        if (data.name) {
          this.tableEidtData = [];
          this.keywords = '';
          this.tableTitle = [];
          switch (data.dataType) {
            case 1:
              this.tableTitle = [
                {
                  name: '名称',
                  porp: 'name',
                  fieldType: 1,
                  maxLength: '30',
                  notNull: '1',
                  id: '1231'
                },
                {
                  name: 'ID',
                  porp: 'id',
                  notNull: '1',
                  id: '123123'
                },
                {
                  name: '经度',
                  porp: 'lng',
                  notNull: '1',
                  fieldType: 11,
                  id: '1231233333'
                },
                {
                  name: '纬度',
                  porp: 'lat',
                  notNull: '1',
                  fieldType: 12,
                  id: '12312333333'
                },
                {
                  name: '高度(m)',
                  porp: 'z',
                  notNull: '1',
                  fieldType: '2',
                  id: '12312333'
                }
              ];
              break;
            case 3:
              this.tableTitle = [
                {
                  name: '名称',
                  porp: 'name',
                  fieldType: 1,
                  maxLength: '30',
                  notNull: '1',
                  id: '1231'
                },
                {
                  name: 'ID',
                  porp: 'id',
                  notNull: '1',
                  id: '123123',
                  showEdit: 'no'
                }
                // {
                //   name: '长度(km)',
                //   porp: 'length',
                //   notNull: '1',
                //   id: '32156',
                //   showEdit: 'no'
                // }
              ];
              break;
            case 5:
              this.tableTitle = [
                {
                  name: '名称',
                  porp: 'name',
                  fieldType: 1,
                  maxLength: '30',
                  notNull: '1',
                  id: '1231'
                },
                {
                  name: 'ID',
                  porp: 'id',
                  notNull: '1',
                  id: '123123',
                  showEdit: 'no'
                }
                // {
                //   name: '面积(km²)',
                //   porp: 'area',
                //   notNull: '1',
                //   id: '1008611',
                //   showEdit: 'no'
                // }
              ];
              break;
            default:
              break;
          }

          this.getTableData();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.drag-content {
  border: none;
}
.isMagnify {
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.dataclassAttrSheetPane {
  min-height: 350px;
  max-height: 89.6%;
  min-width: 1000px;
  width: 1420px;
  height: 350px;
  left: 350px;
  resize: none;
  bottom: 30px;
  position: absolute;

  box-shadow: 0px 3px 7px 0px rgba(189, 195, 212, 0.184);
  .icon {
    cursor: pointer;
  }
  .editBtn {
    width: 114px;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 14px;
    background: #507cff;
  }
  // 分页器
  .pagination {
    width: initial;
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 0px;

    /deep/.btn-prev,
    /deep/.btn-next,
    /deep/ .el-pager li {
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      margin: 0;
      background: transparent;
    }
    /deep/ .el-pager li:not(.disabled).active {
      background: #507cff;
    }
    /deep/.el-pagination__jump {
      margin-left: 7px !important;
    }
  }

  .inputError {
    /deep/.el-input__inner:focus {
      border-color: red;
    }
  }
  .datePicker {
    /deep/.el-input__inner {
      width: 73% !important;
    }
  }
}

// 表格
/deep/.el-table {
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: 0px 3px 7px 0px rgba(80, 124, 255, 0.35);
  margin-bottom: 30px;

  // 表格标题
  th.el-table__cell {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #333333;
    line-height: 38px;
    background: #f4f6ff;
    height: 38px !important;
    padding: 0;
    text-align: 'center';
  }
  // 边框
  &--border {
    border-color: #dcdcdc;
  }
  &__header {
    th:not(.is-hidden):last-child {
      border-left: 1px solid #dcdcdc;
      right: 1px;
    }
  }

  &__row {
    td:not(.is-hidden):last-child {
      border-left: 1px solid #dcdcdc;
      right: 1px;
    }
  }
  // 内容
  &__cell {
    th:not(.is-hidden):last-child {
      border-left: 11px solid #ebeef5;
    }
    line-height: 0;
    font-size: 16px;
    height: 44px !important;
    color: #333333;
    border: none;
    &.is-leaf {
      border: none;
    }
  }
  // 去除表格底部线条
  &::before {
    height: 0px;
  }
  &__fixed-right-patch {
    display: none;
  }
  &__fixed-right {
    box-shadow: -3px 0px 7px 0px rgba(80, 124, 255, 0.3);
    border: none !important;
    background: transparent;
  }
}

// 搜索框
/deep/.search {
  &-btn {
    height: 32px;
    width: 32px;
    border-radius: 6px;
    background-image: url('~a/workbench/icon/icon-attrSheet-search.png');
    background-size: cover;
    margin-left: 200px;
    margin-top: -1px;
    img {
      width: 0;
    }
  }
  &-ipt {
    background: #f4f6ff;
    height: 32px;
    border-radius: 6px;
    width: 232px;
    padding: 0 55px 0 12px;
    border-color: #f4f6ff !important;
    &:focus {
      border-color: #dbe1f9c7 !important;
      background: #e9edfaa3;
    }
  }
}
/deep/.search .el-icon-close {
  right: 36px;
  line-height: 32px !important;
}

// 错误提示角标
.excel {
  width: 100%;
  &-cornerMark {
    position: absolute;
    top: 1px;
    left: 3px;
    width: 16px;
    height: 16px;
    background-image: url('~a/workbench/icon/icon-tip1.png');
    background-size: 16px;
    position: absolute;
  }
}
// 错误提示框
.promptDialogBox {
  top: 40px;
  left: 0px;
  cursor: default;
  width: 100%;
  min-width: 170px;
  height: 60px;
  background: #fff6f6;
  border: 1px solid #ff505070;
  position: absolute;
  z-index: 2;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  &-tip {
    display: flex;
    span {
      display: flex;
      align-items: center;
    }
    justify-content: space-between;
    font-size: 14px;
    .icon-tip {
      width: 14px;
      height: 14px;
      margin: 0 5px;
      cursor: default;
    }
    .icon-attrSheet-close {
      width: 10px;
      height: 10px;
      margin: 5px;
    }
  }
  &-text {
    position: absolute;
    text-align: left;
    margin-left: 24px;
    width: 78%;
    line-height: 16px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
// 滑动条
/deep/::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  &-thumb {
    background: #507cff78;
  }
}

/deep/ .cut-offRule {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #dcdcdc;
  margin: 0 10px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
