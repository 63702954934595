<template>
  <!-- 属性信息项 -->
  <div class="info-item">
    <div class="info-item-label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div class="info-item-text">
      <slot>
        {{ text }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoItem',
  props: {
    label: String,
    text: String
  }
};
</script>

<style lang="less" scoped>
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
  }
  // 左侧名称
  &-label {
    width: 90px;
    margin-right: 10px;
    font-size: 18px;
    text-align: right;
  }
  // 右侧值
  &-text {
    flex: 1;
    font-size: 16px;
  }
}
</style>
