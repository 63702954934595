<template>
  <el-dialog
    title="自定义属性字段设置"
    :visible.sync="dataclassAttrFieldDialogVisible.status"
    width="30.10417vw"
    top="10vh"
    destroy-on-close
  >
    <div class="btn">
      <Button class="btn-add" icon="add-white" @click="handleAdd"
        >添加字段</Button
      >
      <Button
        class="btn-del"
        icon="del-red"
        @click="handleDel"
        v-show="delStatus"
        >删除字段</Button
      >
      <!-- <Button class="btn-up" icon="up-green">向上</Button>
      <Button class="btn-down" icon="down-yellow">向下</Button> -->
    </div>
    <!-- 字段表格 -->
    <el-table
      ref="table"
      @row-click="handleRowClick"
      :row-class-name="tableRowClassName"
      :data="tableData"
      class="table"
      height="15.625vw"
    >
      <el-table-column prop="name" label="属性名称" show-overflow-tooltip />
      <el-table-column label="属性类型">
        <template slot-scope="scope">
          <span v-show="scope.row.fieldType == 1">文本</span>
          <span v-show="scope.row.fieldType == 2">数字</span>
          <span v-show="scope.row.fieldType == 3">日期</span>
          <span v-show="scope.row.fieldType == 4">网页链接</span>
          <span v-show="scope.row.fieldType == 5">电话号码</span>
        </template>
      </el-table-column>
      <el-table-column label="是否必填">
        <template slot-scope="scope">
          <span :class="scope.row.notNull ? 'yes' : 'no'">{{
            scope.row.notNull ? '是' : '否'
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增字段 -->
    <el-form
      class="form"
      label-position="left"
      label-width="6.25vw"
      :disabled="!delStatus"
    >
      <el-form-item label="属性名称：">
        <el-input
          v-model.trim="fieldForm.name"
          :maxlength="20"
          placeholder="请输入属性名称"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="属性类型：">
        <el-select
          v-model="fieldForm.fieldType"
          placeholder="请选择属性类型"
          @change="e => changeFieldType(e, fieldForm)"
        >
          <el-option
            v-for="option in fieldTypes"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="最大长度：">
        <el-input
          v-model="fieldForm.maxLength"
          placeholder="请输入属性最大长度"
          :disabled="fieldForm.fieldType !== 1"
          type="number"
          oninput="if(value<1)value=''"
        />
      </el-form-item>
      <el-form-item label="是否必填：">
        <el-radio-group v-model="fieldForm.notNull">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="form-btn-group">
      <Button
        class="form-btn cancel"
        @click="dataclassAttrFieldDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click="confirm">保存</Button>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import $bus from 'p/utils';
export default {
  name: 'DataclassAttrFieldDialog',
  components: {
    Button
  },
  data() {
    return {
      // 表单行数据
      fieldForm: {
        name: '',
        fieldType: '',
        maxLength: '',
        notNull: true
      },
      // 表格数据
      tableData: [],
      // 类型下拉框
      fieldTypes: [
        { id: 1, name: '文本' },
        { id: 2, name: '数字' },
        { id: 3, name: '日期' },
        { id: 4, name: '网页链接' },
        { id: 5, name: '电话号码' }
      ],
      // 选中的当前行
      currentIndex: '',
      // 删除按钮
      delStatus: false
    };
  },
  computed: {
    dataclassAttrFieldDialogVisible: {
      get() {
        return this.$store.state.data.dataclassAttrFieldDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrFieldDialogVisible', val);
      }
    },
    dataclassAttrDialogVisible: {
      get() {
        return this.$store.state.data.dataclassAttrDialogVisible;
      }
    },
    dataclassAttrSheetPanelVisible: {
      get() {
        return this.$store.state.data.dataclassAttrSheetPanelVisible;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrSheetPanelVisible', val);
      }
    }
  },
  methods: {
    // 获取表格数据
    getTable(id) {
      const params = {
        id: id
      };
      this.$service.data.featureClassAttr(params).then(res => {
        this.tableData = [];
        let arr = res.data.data.customFields;
        arr.map(item => {
          if (item.writable == 1) {
            this.tableData.push(item);
          }
        });
      });
    },
    // 点击行事件
    handleRowClick(row) {
      this.currentIndex = row.index;
      if (row.writable) {
        this.delStatus = true;
      } else {
        this.delStatus = false;
      }
      this.fieldForm = row;
    },
    // 选中行颜色
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (rowIndex === this.currentIndex && this.delStatus) {
        return 'warning-row';
      }
    },
    // 切换属性类型
    changeFieldType() {
      this.fieldForm.maxLength = '';
    },
    // 添加字段
    handleAdd() {
      let num = 1;
      let name = '属性';
      if (this.tableData[0]) {
        for (let i = 0; i < 1000; i++) {
          this.tableData.forEach(item => {
            if (item.name == name + num) {
              num = parseFloat(item.name.slice(2)) + 1;
            }
          });
        }
      }
      const obj = {
        name: name + num,
        fieldType: 1,
        maxLength: null,
        notNull: 0,
        writable: 1,
        index: this.tableData.length
      };
      this.currentIndex = this.tableData.length;
      this.fieldForm = obj;
      this.delStatus = true;
      this.tableData.push(obj);
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop =
          this.$refs.table.bodyWrapper.scrollHeight;
      });
    },
    // 删除字段
    handleDel() {
      this.fieldForm = {
        name: '',
        fieldType: null,
        maxLength: null,
        notNull: true
      };
      this.tableData.splice(this.currentIndex, 1);
      this.delStatus = false;
    },
    // 保存
    confirm() {
      let arr = [];
      let arr1 = [];
      arr = JSON.parse(JSON.stringify(this.tableData));
      arr.forEach(item => {
        if (item.writable !== 0) {
          for (var key in item) {
            key == 'fieldTypeName' && delete item[key];
            key == 'writable' && delete item[key];
            key == 'index' && delete item[key];
          }
          arr1.push(item);
        }
      });
      const params = {
        id: this.dataclassAttrFieldDialogVisible.id,
        fieldJson: JSON.stringify(arr1)
      };
      this.$service.data.featureClassFieldEdit(params).then(res => {
        if (res.data.status === 200) {
          this.$message.success(res.data.msg);
          if (this.dataclassAttrDialogVisible.status) {
            $bus.$emit('refreshAttr');
          }
          if (this.dataclassAttrSheetPanelVisible.status) {
            $bus.$emit('refreshAttrList', 1);
          }
          this.$store.commit('data/setDataclassAttrFieldDialogVisible', false);
        }
      });
    }
  },
  watch: {
    dataclassAttrFieldDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.id) {
          this.getTable(data.id);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.table {
  border: 1px solid #507cff;
  border-radius: 4px;
  /deep/ .el-table {
    &__header {
      .el-table__cell {
        height: 40px;
        line-height: 40px;

        color: #333;
        font-size: 18px;
        &:first-child {
          padding-left: 20px;
        }
      }
    }
    &__body {
      .el-table__cell {
        height: 36px;
        line-height: 36px;

        border-bottom: none;
        color: #666666;
        font-size: 16px;
        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }
}

.form {
  margin-top: 10px;
  padding: 10px 30px 30px 0;
  background: #eaefff;
  border: 1px solid #507cff;
  border-radius: 4px;
  /deep/ .el-form {
    &-item {
      margin-bottom: 10px;
    }
  }
}

.yes {
  color: #f45e0b;
  font-size: 16px;
}
.no {
  color: #19e83b;
  font-size: 16px;
}

.title {
  color: #333;
  font-size: 20px;
  font-weight: bold;
}

.btn {
  display: flex;
  margin: -10px 0 10px;
  &-add {
    width: 117px;
    height: 32px;
    background: #507cff;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
  &-del {
    width: 117px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #f42c0b;
    color: #f42c0b;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
  &-up {
    width: 86px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #1ecd4f;
    color: #1ecd4f;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
  &-down {
    width: 86px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e19a1f;
    color: #e19a1f;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
}
.addFieldBtn {
  background: #507cff;

  width: 146px;
  border-radius: 4px;
  font-size: 16px;
}

// 表格行背景色
/deep/.el-table .warning-row {
  background-color: #e3ecff !important;
  &:hover {
    background-color: #e3ecff !important;
  }
}
</style>
