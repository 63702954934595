<template>
  <!-- 搜索 -->
  <div class="search">
    <!-- 搜索按钮 -->
    <div class="search-btn" @click="$emit('search')">
      <img src="~a/workbench/search.png" alt="" />
    </div>
    <!-- 输入框 -->
    <input
      type="text"
      :value="value"
      class="search-ipt"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('search')"
    />
    <i class="el-icon-close" @click="clear" v-show="value.length > 0" />
  </div>
</template>

<script>
export default {
  name: 'search',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: '请输入搜索关键字'
    }
  },
  methods: {
    clear() {
      this.$emit('input', '');
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  position: relative;
  &-btn {
    position: absolute;
    top: 1px;
    left: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #d3dff7;
    border-radius: 12px;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
  }
  &-ipt {
    box-sizing: border-box;
    width: 295px;
    height: 36px;
    padding-left: 54px;
    padding-right: 30px;
    background: #eff3fc;
    border-radius: 12px;
    border: 1px solid transparent;
    font-size: 14px;
    &::placeholder {
      color: #999999;
    }
    &:focus {
      border: 1px solid #507cff;
    }
  }

  .el-icon-close {
    position: absolute;
    right: 8px;
    line-height: 36px;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
