<template>
  <!-- 可拖动面板 -->
  <div class="drag" v-drag data-drag="drag">
    <!-- 头部 -->
    <div class="drag-header" data-drag="dragHandle">
      <!-- 标题 -->
      <div class="drag-header-title">
        <slot name="header">
          <span>{{ title }}</span>
        </slot>
      </div>
      <!-- 操作按钮 -->
      <div class="drag-header-btns">
        <slot name="headerbtn"></slot>
      </div>
    </div>
    <div class="drag-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drag',
  props: {
    title: String
  }
};
</script>

<style lang="less" scoped>
// 可拖动面板
.drag {
  position: absolute;
  background-color: #fff;
  // 头部
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    cursor: move;
    // 左侧标题
    &-title {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 16px;
        background: #507cff;
      }
    }
    // 右侧按钮
    &-btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      // 图标按钮
      .icon {
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  // 主体
  &-content {
    box-sizing: border-box;
    height: calc(100% - 90px);
    margin: 0 30px 30px;
    border: 1px solid #dcdcdc;
  }
}
</style>
